import AI_MOBILE_VIDEO from "@assets/products/landing/ai-chat-mobile.mp4";
import AI_VIDEO from "@assets/products/landing/ai-chat.mp4";
import NOTEBOOK_MOBILE_VIDEO from "@assets/products/landing/digital-notebook-mobile.mp4";
import NOTEBOOK_VIDEO from "@assets/products/landing/digital-notebook.mp4";
import INVENTORY_MOBILE_VIDEO from "@assets/products/landing/modern-inventory-mobile.mp4";
import INVENTORY_VIDEO from "@assets/products/landing/modern-inventory.mp4";
import { TabsV2, TypographyV2 } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { LearnMoreButtonV3 } from "@components/Button_new/Button";
import { mapToKeyValue } from "@helpers/Hooks";
import React, { ReactNode, useEffect, useRef } from "react";
import { GridCard } from "../_GridCard/_GridCard";
import * as styles from "./Products.module.scss";

import {
	ConsumablesProductsImage,
	EquipmentsProductsImage,
	OrdersProductsImage,
	NotebookIcon,
	InventoryIcon,
	EquipmentIcon,
	ConsumablesIcon,
	OrdersIcon,
	AiIcon,
} from "@assets/products/landing";
import { LinkType } from "@components/Link/Link";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

type ProductCardBaseProps = {
	features: {
		title: string;
		subtitle: string;
	}[];
	learnMoreText?: string;
	title: string;
	mediaType: "Video" | "Image";
	mobileVideo?: any;
	video?: any;
	poster?: any;
	image?: ReactNode;
};

interface ImageProps extends ProductCardBaseProps {
	mediaType: "Image";
	image: ReactNode;
	tabIcon?: ReactNode;
}
interface VideoProps extends ProductCardBaseProps {
	mediaType: "Video";
	mobileVideo: any;
	video: any;
	poster: any;
	tabIcon?: ReactNode;
}

const TAB_CONTENT: ((ImageProps | VideoProps) & {
	to: LinkType;
	tabTitle: string;
	showBackgroundFade?: boolean;
})[] = [
	{
		tabTitle: "Notebook",
		tabIcon: <NotebookIcon />,
		title: "Electronic lab notebook",
		mobileVideo: NOTEBOOK_MOBILE_VIDEO,
		video: NOTEBOOK_VIDEO,
		poster: "/notebook-placeholder.png",
		to: "NOTEBOOK",
		mediaType: "Video",
		features: [
			{
				title: "All-in-one lab notebook",
				subtitle:
					"Electronic Lab Notebook (ELN) designed to streamline your workflow—organize projects, folders, and experiments with real-time collaboration for instant updates and seamless editing.",
			},
			{
				title: "Central protocol library",
				subtitle:
					"Store, organize, and share protocols in one secure location for easy access and seamless collaboration.",
			},
			{
				title: "Audit-ready data",
				subtitle:
					"Maintain data integrity with built-in audit trails and version control, ensuring accuracy and compliance at every step.",
			},
		],
		showBackgroundFade: true,
		learnMoreText: "Learn about our digital notebook",
	},
	{
		tabTitle: "Inventory",
		tabIcon: <InventoryIcon />,
		title: "Inventory management",
		mobileVideo: INVENTORY_MOBILE_VIDEO,
		video: INVENTORY_VIDEO,
		poster: "/boxes-placeholder.png",
		to: "INVENTORY",
		mediaType: "Video",
		features: [
			{
				title: "Simplify sample management",
				subtitle:
					"Our user-friendly interface makes adding reagents a breeze. Choose from preset configurations or create your own custom item types.",
			},
			{
				title: "Secure data, smarter inventory",
				subtitle:
					"Keep your data safe and secure with export options and boost your inventory tracking with printable barcodes.",
			},
			{
				title: "Optimize with advanced tools",
				subtitle:
					"Enjoy streamlined workflows with advanced search filters, a centralized repository, and user-friendly features designed for maximum comfort and efficiency.",
			},
		],
		showBackgroundFade: true,
		learnMoreText: "Learn about inventory management",
	},
	{
		tabTitle: "Equipment",
		tabIcon: <EquipmentIcon />,
		title: "Lab equipment management",
		to: "EQUIPMENT",
		mediaType: "Image",
		image: <EquipmentsProductsImage />,
		features: [
			{
				title: "Boost instrument efficiency",
				subtitle:
					"Get a complete view of all available instruments to optimize usage and minimize downtown.",
			},
			{
				title: "Streamline equipment scheduling",
				subtitle:
					"Use the visual schedule to quickly see when equipment is booked, making planning easier and reducing overlaps.",
			},
			{
				title: "Optimize team coordination",
				subtitle:
					"Promote effective coordination among team members to optimize instrument usage and keep up a continuous workflow.",
			},
		],
		showBackgroundFade: false,
		learnMoreText: "Learn about equipment management",
	},
	{
		tabTitle: "Consumables",
		tabIcon: <ConsumablesIcon />,
		title: "Lab consumables management",
		to: "CONSUMABLES",
		image: <ConsumablesProductsImage />,
		mediaType: "Image",
		features: [
			{
				title: "Customized lab organization",
				subtitle:
					"Design specific sections and categories tailored for your biopharma research needs, ensuring seamless laboratory space management.",
			},
			{
				title: "Streamlined consumable oversight",
				subtitle:
					"Define and track precise item groups to enhance organization and efficiency in managing your biopharma consumables.",
			},
			{
				title: "Enhanced inventory control",
				subtitle:
					"Refine categorization and tracking to maintain stock accuracy and integrity, crucial for supporting biopharma research success.",
			},
		],
		showBackgroundFade: true,
		learnMoreText: "Learn about consumable management",
	},
	{
		tabTitle: "Orders",
		tabIcon: <OrdersIcon />,
		title: "Order management",
		image: <OrdersProductsImage />,
		to: "ORDERS",
		mediaType: "Image",
		features: [
			{
				title: "Track orders easily",
				subtitle:
					"Get a comprehensive view of all order requests and their current status on one dashboard.",
			},
			{
				title: "Real-time status insights",
				subtitle:
					"Keep things clear with high-level overviews, so you always know where each item stands.",
			},
			{
				title: "Faster order processing",
				subtitle:
					"Speed up the order submission process with user-friendly tools, making approvals and processing a breeze.",
			},
		],
		showBackgroundFade: false,
		learnMoreText: "Learn about order management",
	},
	{
		tabTitle: "Genemod AI",
		tabIcon: <AiIcon />,
		title: "Genemod AI",
		mobileVideo: AI_MOBILE_VIDEO,
		video: AI_VIDEO,
		poster: "/ai-placeholder.png",
		to: "GENEMOD_AI",
		mediaType: "Video",
		features: [
			{
				title: "AI-powered insights",
				subtitle:
					"Obtain immediate, accurate scientific insights using Genemod AI's extensive database.",
			},
			{
				title: "Enhance research quality",
				subtitle:
					"Blend various research methods with ease, boosting research efficiency and breadth. ",
			},
			{
				title: "Tailored experiment guidance",
				subtitle:
					"Get tailored advice on experimental processes to meet your specific research needs.",
			},
		],
		showBackgroundFade: true,
		learnMoreText: "Learn about Genemod AI",
	},
];

const VideoComponent = ({
	mobileVideo,
	video,
	poster,
}: {
	mobileVideo: string;
	video: string;
	poster?: string;
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		videoRef.current?.load();
	}, [mobileVideo, video]);

	return (
		<video
			ref={videoRef}
			poster={poster}
			muted
			autoPlay
			loop
			webkit-playsInline
			playsInline
			className={styles.video}
		>
			<source src={video} type="video/mp4" />
			<source
				src={mobileVideo}
				type="video/mp4"
				media="max-width:500px"
			/>
		</video>
	);
};

export default VideoComponent;

interface ProductTab {
	title: string;
	icon: ReactNode;
}

const PRODUCTS_TABS: ProductTab[] = TAB_CONTENT.map((tab) => ({
	title: tab.tabTitle,
	icon: tab.tabIcon,
}));

type ProductsTabs = typeof TAB_CONTENT[number]["tabTitle"];

const formattedTabs = PRODUCTS_TABS.map((tab) => ({
	id: tab.title as ProductsTabs,
	title: tab.title,
	icon: tab.icon,
}));

export const Products = () => {
	const [selectedTab, setSelectedTab] = React.useState<ProductsTabs>(
		TAB_CONTENT[0]["tabTitle"]
	);
	const {
		title,
		to,
		mobileVideo,
		video,
		poster,
		features,
		mediaType,
		image,
		showBackgroundFade,
		learnMoreText,
	} = mapToKeyValue(TAB_CONTENT, "tabTitle")[selectedTab];

	return (
		<div className={styles.industries}>
			<section className={styles.industries_title_tabs}>
				<div className={styles.industries_title}>
					<TypographyV2
						variant="BODY_TEXT_MEDIUM"
						weight="BOLD"
						color="brand-primary-2"
						className={styles.subheader}
					>
						Raising the bar
					</TypographyV2>
					<TypographyV2
						variant="HEADING_2_V2"
						weight="BOLD"
						color="base-black"
						className={styles.industriesSectionHeader}
					>
						Put all of your work in one place
					</TypographyV2>
				</div>
				<div className={styles.tabsContainer}>
					<TabsV2
						tabs={formattedTabs}
						selectedTab={selectedTab}
						onChange={setSelectedTab}
						wrapperClass={styles.tabs}
						tabsClassName={styles.tabElements}
					/>
				</div>
			</section>
			<ProductCardNew
				mobileVideo={mobileVideo}
				video={video}
				poster={poster}
				features={features}
				title={title}
				mediaType={mediaType}
				image={image}
				showBackgroundFade={showBackgroundFade}
				learnMoreText={learnMoreText}
				to={to}
			/>
		</div>
	);
};

const CheckMarkPatter = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 6L9 17L4 12"
				stroke="#2C52F7"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

const ProductCardNew = ({
	features,
	image,
	mobileVideo,
	video,
	poster,
	mediaType,
	showBackgroundFade,
	to,
	learnMoreText,
}: (ImageProps | VideoProps) & {
	showBackgroundFade?: boolean;
	to: LinkType;
}) => {
	return (
		<div className={styles.productCardContainer}>
			<div className={styles.rightContainerBodyWrapper}>
				<div
					className={classNames(
						styles.rightContainer,
						!showBackgroundFade && styles.noFade
					)}
				>
					<div className={styles.videoContainer}>
						{mediaType === "Video" ? (
							<>
								<VideoComponent
									mobileVideo={mobileVideo}
									video={video}
									poster={poster}
								/>
							</>
						) : (
							<>{image}</>
						)}
					</div>
				</div>
			</div>
			<div className={styles.productTextContent}>
				<div className={styles.list}>
					{features.map((feature, index) => {
						return (
							<div key={index} className={styles.item_container}>
								<div className={styles.item}>
									<div className={styles.star}>
										<StarIcon />
									</div>
									<div className={styles.itemText}>
										<TypographyV2
											variant="HEADING_5_V2"
											color="text-color-brown"
											weight="BOLD"
											className={styles.itemTextstyle}
										>
											{feature.title}
										</TypographyV2>
									</div>
								</div>
								<TypographyV2
									color="menu-item"
									weight="REGULAR"
									className={styles.itemSubtitle}
								>
									{feature.subtitle}
								</TypographyV2>
							</div>
						);
					})}
				</div>
				<div>
					<div className={styles.learnMore}>
						<LearnMoreButtonV3
							to={to}
							textClassName={styles.learnMoreButtonText}
							className={styles.learnMoreButtonContainer}
							showArrow={true}
							children={learnMoreText}
							arrowColor="brand-primary"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

function StarIcon() {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11 1L13.012 6.23109C13.294 6.96432 13.435 7.33093 13.6542 7.63931C13.8486 7.91262 14.0874 8.15141 14.3607 8.34575C14.6691 8.56503 15.0357 8.70603 15.7689 8.98804L21 11L15.7689 13.012C15.0357 13.294 14.6691 13.435 14.3607 13.6542C14.0874 13.8486 13.8486 14.0874 13.6542 14.3607C13.435 14.6691 13.294 15.0357 13.012 15.7689L11 21L8.98804 15.7689C8.70603 15.0357 8.56503 14.6691 8.34575 14.3607C8.15141 14.0874 7.91262 13.8486 7.63931 13.6542C7.33093 13.435 6.96432 13.294 6.23109 13.012L1 11L6.23108 8.98804C6.96431 8.70603 7.33093 8.56503 7.63931 8.34575C7.91262 8.15141 8.15141 7.91262 8.34575 7.63931C8.56503 7.33093 8.70603 6.96431 8.98804 6.23108L11 1Z"
				stroke="#895AF6"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
