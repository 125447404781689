// extracted by mini-css-extract-plugin
export var ImageContainer = "_industries-v2-module--ImageContainer--pXq9x";
export var LearnMore = "_industries-v2-module--LearnMore--vvKPM";
export var biopharmaTitle = "_industries-v2-module--biopharmaTitle--jnXD1";
export var biorepositoryTitle = "_industries-v2-module--biorepositoryTitle--owUJo";
export var biotechTitle = "_industries-v2-module--biotechTitle--pPd3w";
export var clinicalTitle = "_industries-v2-module--clinicalTitle--cAlZE";
export var contentContainer = "_industries-v2-module--contentContainer--o9Lw4";
export var contentSection = "_industries-v2-module--contentSection--CCTnG";
export var contentTitle = "_industries-v2-module--contentTitle--jo-qf";
export var customLearnMoreButton = "_industries-v2-module--customLearnMoreButton--klcbA";
export var description = "_industries-v2-module--description--n1sK5";
export var icon = "_industries-v2-module--icon--tG1ES";
export var imageHolder = "_industries-v2-module--imageHolder--owvQI";
export var industries = "_industries-v2-module--industries--BpIqw";
export var industriesContainer = "_industries-v2-module--industriesContainer--nSJO8";
export var industriesWrapper = "_industries-v2-module--industriesWrapper--tVF77";
export var industrySection = "_industries-v2-module--industrySection--h0bfd";
export var industryTitle = "_industries-v2-module--industryTitle--7PUBv";
export var learnMoreButtonText = "_industries-v2-module--learnMoreButtonText--7bhT+";
export var limitWidthOnLargeScreens = "_industries-v2-module--limitWidthOnLargeScreens--8m1eP";
export var margins = "_industries-v2-module--margins--lSnFN";
export var tagline = "_industries-v2-module--tagline--YFswW";
export var title = "_industries-v2-module--title--d8kJb";