// extracted by mini-css-extract-plugin
export var gridCol = "Products-module--gridCol--aIAib";
export var horizontalRule = "Products-module--horizontalRule--CxkGS";
export var industries = "Products-module--industries--DNORv";
export var industriesSectionHeader = "Products-module--industriesSectionHeader--q1mei";
export var industries_title = "Products-module--industries_title--xlHPc";
export var industries_title_tabs = "Products-module--industries_title_tabs--B6XWX";
export var item = "Products-module--item--cpNtv";
export var itemSubtitle = "Products-module--itemSubtitle--x+u3E";
export var itemText = "Products-module--itemText--lOscg";
export var itemTextstyle = "Products-module--itemTextstyle--ClFgX";
export var item_container = "Products-module--item_container--yuUjd";
export var learnMore = "Products-module--learnMore--Td6-E";
export var learnMoreButtonContainer = "Products-module--learnMoreButtonContainer--5tqSb";
export var learnMoreButtonText = "Products-module--learnMoreButtonText--gxHNd";
export var limitWidthOnLargeScreens = "Products-module--limitWidthOnLargeScreens--uzre1";
export var list = "Products-module--list--vLPcL";
export var noFade = "Products-module--noFade--NBmJe";
export var productCardContainer = "Products-module--productCardContainer--khsmU";
export var productTextContent = "Products-module--productTextContent--7CCx6";
export var productTitleText = "Products-module--productTitleText--DRAed";
export var rightContainer = "Products-module--rightContainer--2A60I";
export var rightContainerBodyWrapper = "Products-module--rightContainerBodyWrapper--SCAzs";
export var star = "Products-module--star--kXrxK";
export var subheader = "Products-module--subheader--VoYra";
export var tabElements = "Products-module--tabElements--w48V9";
export var tabsContainer = "Products-module--tabsContainer--vyTRL";
export var videoContainer = "Products-module--videoContainer--DS1tn";