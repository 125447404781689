import React from "react";
import * as styles from "./_industries-v2.module.scss";
import { TypographyV2 } from "@components/index";
import { LearnMoreButtonV3 } from "@components/Button_new/Button";
import Margins from "@components/Margins/Margins";

import {
	BIOMANUFACTURES,
	BioManufacturing3,
	BIOPHARMA,
	BiopharmaceuticalRD3,
	BIOREPOSITORIES,
	BiorepositoryNew,
	CLINICALRESEARCH,
	ClinicalResearchNew,
	CONTRACTSERVICES,
	ContractServices3,
	INDUSTRIALBIOTECH,
	IndustrialBiotech3,
} from "@assets/images/landing_page";
import classNames from "classnames";
type Industries = {
	buttonText: string;
	image: JSX.Element;
	icon: React.ReactNode;
	tagline: string;
	title: JSX.Element;
	detail: string;
};

export default function IndustriesV2() {
	const industryDetails: Industries[] = [
		{
			buttonText: "For biopharma innovations",
			image: <BiopharmaceuticalRD3 />,
			icon: <BIOPHARMA />,
			tagline: "BIOPHARMACEUTICAL",
			title: (
				<TypographyV2
					color="text-color"
					variant="BODY_TEXT_LARGE_V2"
					weight="BOLD"
					className={classNames(styles.title, styles.biopharmaTitle)}
				>
					Integrated solutions for biopharma R&D decision-making
				</TypographyV2>
			),
			detail: "Embrace an efficient R&D solution tailored specifically for biopharma. Our platform seamlessly integrates swift lead identification with informed decision-making, bolstered by tools that foster team collaboration. It's an elevated approach to R&D, blending practicality with actionable insights.",
		},
		{
			buttonText: "Scale your biotech",
			image: <IndustrialBiotech3 />,
			icon: <INDUSTRIALBIOTECH />,
			tagline: "INDUSTRIAL BIOTECH",
			title: (
				<TypographyV2
					color="text-color"
					variant="BODY_TEXT_LARGE_V2"
					weight="BOLD"
					className={classNames(styles.title, styles.biotechTitle)}
				>
					Unified R&D toolkit for industrial biotech
				</TypographyV2>
			),
			detail: "Navigate the bioeconomy frontier with a comprehensive R&D toolkit. Our platform seamlessly fuses strain design with process upscaling, underpinned by holistic sample traceability on a unified digital landscape. It's a forward-thinking approach to R&D, merging inventory management with integrated lab instrument connectivity for data precision and traceability.",
		},
		{
			buttonText: "Optimize biorepository processes",
			image: <BiorepositoryNew />,
			icon: <BIOREPOSITORIES />,
			tagline: "BIOREPOSITORY",
			title: (
				<TypographyV2
					color="text-color"
					variant="BODY_TEXT_LARGE_V2"
					weight="BOLD"
					className={classNames(
						styles.title,
						styles.biorepositoryTitle
					)}
				>
					Streamlined biorepository efficiency
				</TypographyV2>
			),
			detail: "Delve into advanced biorepository management with our intuitive platform. Seamlessly visualize your research space, enhanced by Genemod's Consumable space feature, ensuring swift location and tracking of every specimen. Our approach harmonizes automated experiment flow with integrated data tools, propelling you towards efficient collaboration within and beyond your lab's boundaries.",
		},
		{
			buttonText: "Streamline clinical research",
			image: <ClinicalResearchNew />,
			icon: <CLINICALRESEARCH />,
			tagline: "CLINICAL RESEARCH",
			title: (
				<TypographyV2
					color="text-color"
					variant="BODY_TEXT_LARGE_V2"
					weight="BOLD"
					className={classNames(styles.title, styles.clinicalTitle)}
				>
					Innovative clinical research solutions for data-driven
					analytics
				</TypographyV2>
			),
			detail: "Elevate clinical research with Genemod’s laboratory management software, designed to manage and analyze complex clinical trial data with unparalleled precision. Our platform streamlines the entire clinical research process, from patient enrollment to data collection and analysis. By integrating advanced analytics, Genemod’s laboratory management software accelerates the discovery of insights, fostering faster decision-making and more effective trial outcomes.",
		},
		{
			buttonText: "Enhance biomanufacturing",
			image: <BioManufacturing3 />,
			icon: <BIOMANUFACTURES />,
			tagline: "BIOMANUFACTURING",
			title: (
				<TypographyV2
					color="text-color"
					variant="BODY_TEXT_LARGE_V2"
					weight="BOLD"
					className={classNames(styles.title, styles.clinicalTitle)}
				>
					Revolutionizing biomanufacturing with precision and
					scalability
				</TypographyV2>
			),
			detail: "Genemod’s laboratory management software is tailored to meet the unique demands of biomanufacturing. It offers comprehensive tools for managing bioprocess workflows, tracking batch production, and ensuring quality control. The software facilitates the efficient handling of large-scale biological data, essential in biomanufacturing operations, and ensures adherence to industry regulations and standards.",
		},
		{
			buttonText: "Custom solutions",
			image: <ContractServices3 />,
			icon: <CONTRACTSERVICES />,
			tagline: "CONTRACT SERVICES",
			title: (
				<TypographyV2
					color="text-color"
					variant="BODY_TEXT_LARGE_V2"
					weight="BOLD"
					className={classNames(styles.title, styles.clinicalTitle)}
				>
					Advancing contract services with strategic operational
					insights
				</TypographyV2>
			),
			detail: "Optimize contract service management with Genemod’s LIMS and ELN software, engineered to enhance operational efficiency and client satisfaction. It provides a centralized platform for managing research samples, tracking project progress, and maintaining comprehensive documentation.",
		},
	];

	return (
		<div className={styles.industriesWrapper}>
			<Margins className={styles.margins}>
				<div
					className={styles.industriesContainer}
				>
					<div className={styles.industries}>
							<TypographyV2
								variant="HEADING_2"
								tabletVariant="HEADING_2"
								weight="BOLD"
								color="brand-black"
								className={styles.contentTitle}
							>
								Driving growth across scientific industries
							</TypographyV2>
						<div className={styles.contentContainer}>
							{industryDetails?.map((industry, idx) => {
								return (
									<div key={idx} className={styles.industrySection}>
										<div className={styles.ImageContainer}>
											<div className={styles.imageHolder}>
												{industry.image}
											</div>
										</div>
										<div className={styles.contentSection}>
											<div>
												<TypographyV2
													color="brand-accent-1"
													variant="BODY_TEXT_MEDIUM"
													weight="SEMIBOLD"
													className={styles.tagline}
												>
													{industry?.tagline}
												</TypographyV2>

												<div
													className={
														styles.industryTitle
													}
												>
													{industry.icon && (
														<span
															className={
																styles.icon
															}
														>
															{industry.icon}
														</span>
													)}
													{industry.title}
												</div>
												<TypographyV2
													className={
														styles.description
													}
													variant="BODY_TEXT_MEDIUM"
													color="menu-item"
													weight="REGULAR"
												>
													{industry?.detail}
												</TypographyV2>
											</div>
											<div className={styles?.LearnMore}>
												<LearnMoreButtonV3
													text={industry.buttonText}
													arrowColor="brand-primary-2"
													textClassName={
														styles.learnMoreButtonText
													}
													className={
														styles.customLearnMoreButton
													}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</Margins>
		</div>
	);
}
