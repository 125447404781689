// extracted by mini-css-extract-plugin
export var ArrowContainer = "HomePageTestimonials-module--ArrowContainer--1WZeg";
export var HeaderTextsContainer = "HomePageTestimonials-module--HeaderTextsContainer--iUuVx";
export var HeadingContainer = "HomePageTestimonials-module--HeadingContainer--7wGYT";
export var HomePageTestimonialsContainer = "HomePageTestimonials-module--HomePageTestimonialsContainer--YJvdP";
export var TestimonialContainer = "HomePageTestimonials-module--TestimonialContainer--UEJ5x";
export var TestimonialItem = "HomePageTestimonials-module--TestimonialItem--dZHU5";
export var authorDetails = "HomePageTestimonials-module--authorDetails--OlMWA";
export var authorName = "HomePageTestimonials-module--authorName--bAMT8";
export var authorTitle = "HomePageTestimonials-module--authorTitle--jAcUM";
export var headerText = "HomePageTestimonials-module--headerText--Nbs6v";
export var limitWidthOnLargeScreens = "HomePageTestimonials-module--limitWidthOnLargeScreens--PeRo5";
export var quote = "HomePageTestimonials-module--quote--80Pak";
export var subHeaderText = "HomePageTestimonials-module--subHeaderText--oTPWr";
export var widthOnLargeScreensClass = "HomePageTestimonials-module--widthOnLargeScreensClass--20l69";