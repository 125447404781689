// extracted by mini-css-extract-plugin
export var content = "LogosAndG2Section-module--content--zz27S";
export var gridContainer = "LogosAndG2Section-module--gridContainer--UJAhN";
export var image = "LogosAndG2Section-module--image--fEIpv";
export var letsTalk = "LogosAndG2Section-module--letsTalk--doE8X";
export var limitWidthOnLargeScreens = "LogosAndG2Section-module--limitWidthOnLargeScreens--WmJU6";
export var logoAndText = "LogosAndG2Section-module--logoAndText--AF4VH";
export var logoImage = "LogosAndG2Section-module--logoImage--oI0s9";
export var marginSectionWithLogos = "LogosAndG2Section-module--marginSectionWithLogos--MeI-L";
export var requestDemoButtonV2Container = "LogosAndG2Section-module--requestDemoButtonV2Container--R260I";
export var text = "LogosAndG2Section-module--text--XdeCw";
export var title = "LogosAndG2Section-module--title--BF5B9";