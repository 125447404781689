import { ArrowLeftIcons, ArrowRightIcons } from "@assets/icons";
import { PageMargins, TypographyV2 } from "@components";
import { Carousel } from "antd";
import classNames from "classnames";
import React, { FC, Ref, useRef, useEffect } from "react";
import * as styles from "./HomePageTestimonials.module.scss";
import { CarouselRef } from "antd/lib/carousel";

export const HomePageTestimonials = () => {
	const carouselRef = useRef<CarouselRef>(null);
	useEffect(() => {
		if (carouselRef?.current) {
			carouselRef.current?.goTo(0);
		}
	}, []);

	return (
		<div className={styles.HomePageTestimonialsContainer}>
			<div className={styles.HeadingContainer}>
				<div className={styles.HeaderTextsContainer}>
					<TypographyV2
						variant="BODY_TEXT_MEDIUM"
						tabletVariant="HEADING_3"
						mobileVariant="HEADING_3"
						weight="BOLD"
						className={styles.subHeaderText}
					>
						Testimonials
					</TypographyV2>
					<TypographyV2
						variant="HEADING_2"
						tabletVariant="HEADING_3"
						mobileVariant="HEADING_3"
						weight="BOLD"
						className={styles.headerText}
					>
						What our clients are saying
					</TypographyV2>
				</div>
				<div className={styles.ArrowContainer}>
					<div onClick={() => (carouselRef.current as any)?.prev()}>
						{ArrowLeftIcons("brand", "arrow-cirle-left")}
					</div>
					<div onClick={() => (carouselRef.current as any)?.next()}>
						{ArrowRightIcons("brand", "arrow-cirle-right")}
					</div>
				</div>
			</div>
			<div
				className={classNames(
					styles.HomePageTestimonialsItems,
					styles.TestimonialContainer
				)}
			>
				<Carousel
					ref={carouselRef}
					slidesToShow={4}
					// autoplay
					arrows
					infinite={true}
					dots={false}
					draggable
					initialSlide={0}
					responsive={[
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 3,
								item: TestimonialsContentArr,
								infinite: false,
							},
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 1,
								item: TestimonialsContentArr,
								infinite: false,
							},
						},
					]}
				>
					{[...TestimonialsContentArr, ...TestimonialsContentArr].map(
						(each) => (
							<TestimonialCard key={each?.quote} item={each} />
						)
					)}
				</Carousel>
			</div>
		</div>
	);
};
type TestimonialCardProps = {
	item: { quote: string; author: string; authorTitle: string };
};

const TestimonialCard: FC<TestimonialCardProps> = ({ item }) => {
	return (
		<div className={styles.TestimonialItem}>
			<TypographyV2 className={styles.quote}>{item.quote}</TypographyV2>
			<div className={styles.authorDetails}>
				<TypographyV2 className={styles.authorName}>
					{item.author}
				</TypographyV2>
				<TypographyV2 className={styles.authorTitle}>
					{item.authorTitle}
				</TypographyV2>
			</div>
		</div>
	);
};

const TestimonialsContentArr: ReadonlyArray<TestimonialCardProps["item"]> = [
	{
		quote: `“UI of Genemod platform is really easy to use and integrating our previous research data into Genemod system is very convenient.”`,
		author: "Jiah L.",
		authorTitle: "Small-Business (50 or fewer emp.)",
	},
	{
		quote: `“Genemod is a formidable ally for researchers seeking to elevate laboratory management practices. As a researcher who values efficiency and precision, I wholeheartedly recommend Genemod to enhance research capabilities.”`,
		author: "Bhayva V.",
		authorTitle: "Enterprise (>1000 emp.)",
	},
	{
		quote: `“We used excel spreasheets and google docs for inventory management and report generation, but Genemod helped to accelerate our research through easier management of our inventory and lab data.”`,
		author: "Lydia M.",
		authorTitle: "Enterprise (>1000 emp.)",
	},
	{
		quote: `“We have been using Genemod for a short period of time, but are already finding the interface and ease of use to be much better than our current inventory software.”`,
		author: "Anton N.",
		authorTitle: "Small-Business (50 or fewer emp.)",
	},
];
