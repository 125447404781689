import { PageLayout } from "@components";
import { HomePageTestimonials } from "@components/HomePageTestimonials";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";
import { graphql } from "gatsby";
import React from "react";
import { ResourcesBlogPageProps } from "./blog";
import { FeatureSummary } from "./landingpagev2/_components/_FeatureSummary/_FeatureSummary";
import HeroV3 from "./landingpagev2/_components/_HeroV3";
import IndustriesV2 from "./landingpagev2/_components/_Industries/_Industries-v2";

import {
	ValueSection,
} from "./landingpagev2/_components/_Partners/_Partners";
import { Products } from "./landingpagev2/_components/_Products/_Products";
import ResearchTeam from "./landingpagev2/_components/_ResearchTeam/_ResearchTeam";
export default function LandingPageV2({ data }: ResourcesBlogPageProps) {
	const { blogs } = data;

	return (
		<PageLayout
			footerTheme="dark"
			seoOptions={{ type: "PREDEFINED", pageName: "why-genemod" }}
			fixedHeaderColor
		>
			<HeroV3 />
			<ValueSection />
			<Products />
			<IndustriesV2 />
			<HomePageTestimonials />
			<LogosAndG2Section />
		</PageLayout>
	);
}

const AdsCustomerQuotes = [
	{
		quote: `“User-friendly interface, powerful features, and commitment to security and compliance make it a standout choice in the competitive landscape of laboratory software.”`,
		author: "Bhavya V.",
		authorTitle: "Research Scientist",
	},
	{
		quote: `“My favorite aspect of Genemod is the dynamic inventory storage system which enables you to walk through freezers and boxes digitally.”`,
		author: "Christopher O.",
		authorTitle: "Research Scientist",
	},
	{
		quote: `“Genemod allows our company to manage inventory data end-to-end in one single platform. It is very easy to use and has greatly improved our efficiency.”`,
		author: "Ryan K.",
		authorTitle: "Sr. Scientist",
	},
];
export const pageQuery = graphql`
	{
		blogs: allNodeBlog(sort: { fields: created, order: DESC }) {
			nodes {
				title
				body {
					processed
					summary
				}
				created(formatString: "MMMM DD, YYYY")
				path {
					alias
				}
				relationships {
					field_blog_category {
						name
					}
					field_blog_feature_image {
						relationships {
							field_media_image {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 684)
									}
								}
							}
						}
						field_media_image {
							alt
						}
					}
				}
			}
		}
	}
`;
