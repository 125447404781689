import Margins from "@components/Margins/Margins";
import React, { FC } from "react";

import { RequestDemoButtonV2 } from "@components/Button_new/Button";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { useWindowSize } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./LogosAndG2Section.module.scss";

type LogosAndG2SectionProps = {
	hasLogo?: boolean;
};

export const LogosAndG2Section: FC<LogosAndG2SectionProps> = ({
	hasLogo = false,
}) => {
	const { isMobile, isTablet } = useWindowSize();

	const defaultWidth = 216;
	const logomobileWidth = hasLogo && isMobile && 169;
	const tabletWidth = isTablet && 174;
	const Logo = (
		<StaticImage
			loading="eager"
			src="../../assets/solutions/logo.png"
			alt="Genemod logo"
			style={{ width: logomobileWidth || tabletWidth || defaultWidth }}
		/>
	);
	return (
		<Margins className={styles.marginSectionWithLogos}>
			<div style={{ maxWidth: "1400px", margin: "auto" }}>
				<div className={styles.gridContainer}>
					{hasLogo ? (
						<div className={styles.logoAndText}>
							<div className={styles.logoImage}>{Logo}</div>

							<TypographyV2
								variant="HEADING_2"
								center={isMobile ? true : false}
								className={styles.text}
							>
								See why thousands of scientists trust us
							</TypographyV2>
						</div>
					) : (
						<div className={styles.letsTalk}>
							<div className={styles.title}>Let’s Talk</div>
							<div className={styles.content}>
								Connect with an expert to see if Genemod is
								right for you.
							</div>
							<RequestDemoButtonV2
								mobileFullWidth={false}
								linkClassName={
									styles.requestDemoButtonV2Container
								}
							/>
						</div>
					)}
					<div className={styles.image}>
						<StaticImage
							loading="eager"
							src={"./_assets/LetsTalk.png"}
							alt="solution-g2"
						/>
					</div>
				</div>
			</div>
		</Margins>
	);
};
