import classNames from "classnames";
import React, { HTMLProps, ReactNode } from "react";
import * as styles from "./Margins.module.scss";

type Props = {
	children: ReactNode | ReactNode[];
} & HTMLProps<HTMLDivElement>;

const Margins = ({ children, className, ...props }: Props) => {
	return (
		<div
			className={classNames(styles.margins, className)}
			{...props}
		>
			{children}
		</div>
	);
};

export default Margins;
