// extracted by mini-css-extract-plugin
export var actions = "HeroV3-module--actions--Hz0-q";
export var content = "HeroV3-module--content--g1yXO";
export var description = "HeroV3-module--description--9Rl8R";
export var largeScreen = "HeroV3-module--largeScreen--fZnr+";
export var layout = "HeroV3-module--layout--51i2y";
export var limitWidthOnLargeScreens = "HeroV3-module--limitWidthOnLargeScreens--CGEQw";
export var sectionContainer = "HeroV3-module--sectionContainer--4xopz";
export var sectionMargins = "HeroV3-module--sectionMargins--G5Qsq";
export var sectionWrapper = "HeroV3-module--sectionWrapper--30i-I";
export var signUpAction = "HeroV3-module--signUpAction--S0uFY";
export var smallScreen = "HeroV3-module--smallScreen--RBYDa";
export var title = "HeroV3-module--title--f79lm";
export var videoContainer = "HeroV3-module--videoContainer--OAFRX";
export var videoMask = "HeroV3-module--videoMask--WaNCa";
export var videoWrapper = "HeroV3-module--videoWrapper--pxS0C";